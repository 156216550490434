type GetDecimals = (x: number) => number;
const getDecimals: GetDecimals = x => {
  switch (true) {
    case x < 10:
      return 2;
    case x < 100:
      return 1;
    default:
      return 0;
  }
};

type FormatNumber = (x: number) => string;
export const formatNumber: FormatNumber = x => {
  return isNaN(x)
    ? '-'
    : new Intl.NumberFormat('en-US', {
        minimumFractionDigits: getDecimals(x),
        maximumFractionDigits: getDecimals(x),
      }).format(x);
};
