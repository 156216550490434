import './Spinner.scss';

import { Fragment, useEffect, useState } from 'react';

const SPINNER_DELAY = 100;
export const Spinner = () => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), SPINNER_DELAY);

    return () => clearTimeout(timer);
  });

  if (!showSpinner) {
    return <Fragment />;
  }

  return (
    <div className="container">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
