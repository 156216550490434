import './SettingsButton.scss';

import { FC } from 'react';

type SettingsButton = FC<{
  showSettings: () => void;
}>;
export const SettingsButton: SettingsButton = ({ showSettings }) => {
  return (
    <button className="settings-button" onClick={showSettings}>
      <i className="fa fa-chevron-up fa-3x"></i>
    </button>
  );
};
