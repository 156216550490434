import './Table.scss';

import { flexRender } from '@tanstack/react-table';

import { Spinner } from '../../components/Spinner';
import { useTableContext } from './TableContext';

export const Table = () => {
  const { headers, rows, isLoading } = useTableContext();

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <table cellSpacing={0}>
      <thead className="sticky top">
        <tr>
          {headers.map((header, idx) => (
            <th className={`cell center ${idx === 0 ? 'fixed-width sticky left top' : ''}`} key={header.id}>
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr key={row.id}>
            {row.getVisibleCells().map((cell, idx) => (
              <td
                className={`cell ${idx === 0 ? 'fixed-width center sticky left tanned bold' : 'right'}`}
                key={cell.id}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
