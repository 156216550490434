import './Slider.scss';

import { ChangeEventHandler, FC, useCallback } from 'react';

type Slider = FC<{
  title: string;
  min: number;
  max: number;
  step: number;
  value: number;
  onChange: (value: number) => void;
  disabled?: boolean;
}>;
export const Slider: Slider = ({ title, min, max, step, value, onChange, disabled = false }) => {
  const updateValue: ChangeEventHandler<HTMLInputElement> = useCallback(e => {
    onChange(parseFloat(e.target.value));
  }, []);

  return (
    <div className="input-param">
      <span className="slider-label">{title}</span>
      <div className={`slider${disabled ? ' disabled' : ''}`}>
        <input
          type="range"
          name={title}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={updateValue}
          className="right"
          disabled={disabled}
        />
        <span>{value}</span>
      </div>
    </div>
  );
};
