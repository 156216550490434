import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import type { Column, Header, Row } from '@tanstack/react-table';
import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';

import { Data } from '../../types';
import { formatNumber } from '../../util/format-number';
import { WorkerHandler } from '../../workers/call-worker';
import { useSettingsContext } from '../settings/SettingsContext';

const columnHelper = createColumnHelper<Data>();

const workerHandler = new WorkerHandler();

type TableContextProvider = {
  headers: Header<Data, unknown>[];
  rows: Row<Data>[];
  columns: Column<Data>[];
  isLoading: boolean;
  children?: ReactNode;
};
const TableContext = createContext({} as TableContextProvider);
export const TableContextProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { activeColumns, config } = useSettingsContext();

  const [tempData, setTempData] = useState<Data[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        const tempData = (await workerHandler.call({
          type: 'generateData',
          config: JSON.stringify(config),
        })) as Data[];
        const approximateAPYs = (await workerHandler.call({
          type: 'getApproximateAPYs',
          config: JSON.stringify(config),
          data: JSON.stringify(tempData),
        })) as number[];
        setTempData(
          tempData.map((x, idx) => ({ ...x, approximateAPY: approximateAPYs[idx] })).slice(0, config.SIM_LENGTH_IN_DAYS),
        );
        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    })();
  }, [config]);


  // useEffect(() => {
  //   setIsLoading(true);
  //   (async () => {
  //     const tempData = await generateDataWorker(config);
  //     const approximateAPYs = await generateAPYsWorker(tempData, config);
  //     setTempData(
  //       tempData.map((x, idx) => ({ ...x, approximateAPY: approximateAPYs[idx] })).slice(0, config.SIM_LENGTH_IN_DAYS),
  //     );
  //     setIsLoading(false);
  //   })();
  // }, [config]);

  const data = useMemo(() => tempData, [tempData]);

  const columns = useMemo(
    () =>
      activeColumns.map(([accessor, { label: Header }]) =>
        columnHelper.accessor(accessor as keyof Data, {
          header: () => Header,
          cell: info => (accessor === 'id' ? info.cell.getValue() : formatNumber(info.cell.getValue() as number)),
          footer: info => info.column.id,
        }),
      ),
    [activeColumns],
  );

  const [columnVisibility, setColumnVisibility] = useState({});
  const { getHeaderGroups, getRowModel, getAllLeafColumns } = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });
  const headers = getHeaderGroups()[0].headers;

  return (
    <TableContext.Provider value={{ headers, rows: getRowModel().rows, columns: getAllLeafColumns(), isLoading }}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => {
  return useContext(TableContext);
};
