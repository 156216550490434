export const saveItem =
  <T>(key: string) =>
  (item: T) => {
    localStorage.setItem(key, JSON.stringify(item));
    return item;
  };

export const getItem = <T>(key: string): T | null => {
  const savedItem = localStorage.getItem(key);
  return savedItem && JSON.parse(savedItem);
};
