import { compose } from 'ramda';
import { FC, ReactNode, createContext, useCallback, useContext, useMemo, useState } from 'react';

import { settingsConf } from '../../conf/settings-conf';
import { Config, Data } from '../../types';
import { getItem, saveItem } from '../../util/storage';
import { TableColumns, initialTableColumns } from '../../util/table-columns';

type ActiveColumns = [
  keyof Data,
  {
    label: string;
    visible: boolean;
  },
][];

type SettingsContext = {
  columns: TableColumns;
  saveColumns: (newColumns: TableColumns) => void;
  activeColumns: ActiveColumns;
  config: Config;
  saveConfig: (newConfig: Config) => void;
};
const SettingsContext = createContext({} as SettingsContext);
export const SettingsContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [columns, setColumns] = useState<TableColumns>(() => getItem('columns') || initialTableColumns);
  const saveColumns = useCallback(compose(setColumns, saveItem('columns')), []);
  const activeColumns = useMemo(() => Object.entries(columns).filter(a => a[1].visible) as ActiveColumns, [columns]);
  const [config, setConfig] = useState<Config>(
    () =>
      getItem('config') ||
      (Object.fromEntries(Object.entries(settingsConf).map(([key, value]) => [key, value.initial])) as Config),
  );
  const saveConfig = useCallback(compose(setConfig, saveItem('config')), []);

  return (
    <SettingsContext.Provider value={{ columns, saveColumns, activeColumns, config, saveConfig }}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => {
  return useContext(SettingsContext);
};
