import './SettingsPanel.scss';

import { FC, useCallback, useMemo, useState } from 'react';

import { settingsConf } from '../../conf/settings-conf';
import type { SettingsConf } from '../../conf/settings-conf';
import { Config, Data } from '../../types';
import { useSettingsContext } from './SettingsContext';
import { Slider } from './Slider';

type SettingsPanel = FC<{
  closeSettings: () => void;
}>;
export const SettingsPanel: SettingsPanel = ({ closeSettings }) => {
  const { columns, saveColumns, config, saveConfig } = useSettingsContext();
  const [localCols, setLocalCols] = useState(columns);
  const [localConf, setLocalConf] = useState(config);

  const toggleColumn = useCallback(
    (columnId: keyof Data) => () => {
      setLocalCols(cols => ({ ...cols, [columnId]: { ...cols[columnId], visible: !cols[columnId]?.visible } }));
    },
    [],
  );

  const updateLocalConf = useCallback(
    (prop: keyof Config) => (value: number) => {
      setLocalConf(prevConf => ({ ...prevConf, [prop]: value }));
    },
    [],
  );

  const saveLocalCols = useCallback(() => {
    saveColumns(localCols);
    saveConfig(localConf);
    closeSettings();
  }, [localCols, localConf]);

  const sliderKeys = useMemo(() => {
    // eslint-disable-next-line fp/no-mutating-methods
    return Object.entries(settingsConf)
      .slice()
      .sort(([_1, val1], [_2, val2]) => val1.order - val2.order)
      .map(([key]) => key);
  }, [settingsConf]);

  return (
    <div className="settings-panel">
      <div className="visible-columns">
        <h2>Visible Columns</h2>
        {Object.entries(localCols)
          .slice(1)
          .map(([id, { label, visible }]) => (
            <label className="form-control" key={id}>
              <input
                name={`checkbox${visible ? '-checked' : ''}`}
                {...{
                  type: 'checkbox',
                  checked: visible,
                  onChange: toggleColumn(id as keyof Data),
                }}
              />{' '}
              {label}
            </label>
          ))}
      </div>
      <div className="input-params-container">
        <div className="sliders">
          {sliderKeys.map(sliderKey => (
            <Slider
              key={sliderKey}
              title={settingsConf[sliderKey as keyof SettingsConf].label}
              min={settingsConf[sliderKey as keyof SettingsConf].min}
              max={settingsConf[sliderKey as keyof SettingsConf].max}
              step={settingsConf[sliderKey as keyof SettingsConf].step}
              onChange={updateLocalConf(sliderKey as keyof SettingsConf)}
              value={localConf[sliderKey as keyof SettingsConf]}
              disabled={settingsConf[sliderKey as keyof SettingsConf].disabled}
            />
          ))}
        </div>
        <div className="buttons">
          <button className="btn inverted" onClick={closeSettings}>
            Cancel
          </button>
          <button className="btn primary" onClick={saveLocalCols}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
