import { Config } from '../types';

export type SettingsConf = {
  [key in keyof Config]: {
    order: number;
    label: string;
    initial: number;
    min: number;
    max: number;
    step: number;
    disabled: boolean;
  };
};
export const settingsConf: SettingsConf = {
  SIM_LENGTH_IN_DAYS: {
    order: 1,
    label: 'Simulation Length in Days',
    initial: Math.floor(365 * 1.5),
    min: 180,
    max: 365 * 4,
    step: 1,
    disabled: false,
  },
  INITIAL_PLEDGE: { order: 2, label: 'Initial pledge', initial: 200, min: 20, max: 1000, step: 10, disabled: false },
  DAILY_PLEDGE_GROWTH: {
    order: 3,
    label: 'Daily Pledge Growth %',
    initial: 1,
    min: -2,
    max: 10,
    step: 0.01,
    disabled: false,
  },
  PLEDGED_PER_MINED_FIL: {
    order: 4,
    label: 'Pledged Per Mined FIL',
    initial: 394,
    min: 1,
    max: 1000,
    step: 1,
    disabled: false,
  },
  LIQUIDITY_BUFFER: { order: 5, label: 'Liquidity Buffer', initial: 1, min: 1, max: 5, step: 0.1, disabled: false },
  MINING_REVENUE: { order: 6, label: 'Mining Revenue %', initial: 25, min: 0, max: 100, step: 1, disabled: false },
  PROTOCOL_REVENUE: { order: 7, label: 'Protocol Revenue %', initial: 10, min: 0, max: 100, step: 1, disabled: false },
  VESTED_REWARDS_RATIO: {
    order: 8,
    label: 'Vested Rewards Ratio',
    initial: 0.75,
    min: 0,
    max: 1,
    step: 0.01,
    disabled: true,
  },
  UNVESTING_PERIOD_IN_DAYS: {
    order: 9,
    label: 'Unvesting Period in Days',
    initial: 180,
    min: 1,
    max: 365,
    step: 1,
    disabled: true,
  },
};
