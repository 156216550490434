import { useCallback, useState } from 'react';

import { SettingsButton } from './components/SettingsButton';
import { SettingsContextProvider } from './features/settings/SettingsContext';
import { SettingsPanel } from './features/settings/SettingsPanel';
import { Table } from './features/table/Table';
import { TableContextProvider } from './features/table/TableContext';

const App = () => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const toggleSettings = useCallback(() => {
    setSettingsVisible(prev => !prev);
  }, []);

  return (
    <SettingsContextProvider>
      <TableContextProvider>
        {settingsVisible ? (
          <SettingsPanel closeSettings={toggleSettings} />
        ) : (
          <SettingsButton showSettings={toggleSettings} />
        )}
        <Table />
      </TableContextProvider>
    </SettingsContextProvider>
  );
};

export default App;
