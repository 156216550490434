import { Data } from '../types';

export type TableColumns = {
  [key in keyof Partial<Data>]: { label: string; visible: boolean };
};
export const initialTableColumns: TableColumns = {
  id: { label: 'Day', visible: true },
  staked: { label: 'Staked', visible: true },
  stakedSum: { label: 'Staked Σ', visible: true },
  pledged: { label: 'Pledged', visible: true },
  pledgedSum: { label: 'Pledged Σ', visible: true },
  mined: { label: 'Mined', visible: true },
  minedSum: { label: 'Mined Σ', visible: true },
  unvested: { label: 'Unvested', visible: true },
  unvestedSum: { label: 'Unvested Σ', visible: true },
  miningRewards: { label: 'Mining Reward', visible: true },
  miningRewardsSum: { label: 'Mining Reward Σ', visible: true },
  stakingRewards: { label: 'Staking Reward', visible: true },
  stakingRewardsSum: { label: 'Staking Reward Σ', visible: true },
  protocolRewards: { label: 'Protocol Reward', visible: true },
  protocolRewardsSum: { label: 'Protocol Reward Σ', visible: true },
  approximateAPY: { label: 'Approximate APY %', visible: true },
};
